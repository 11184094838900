import { default as loginmNRU5AbfbnMeta } from "/app/apps/admin/pages/auth/login.vue?macro=true";
import { default as index1sKXKiOnAvMeta } from "/app/apps/admin/pages/finance/index.vue?macro=true";
import { default as indexRhbCo9xKafMeta } from "/app/apps/admin/pages/index.vue?macro=true";
import { default as _91id_938P31KSEikOMeta } from "/app/apps/admin/pages/merchants/[id].vue?macro=true";
import { default as createEoHtO5AzvfMeta } from "/app/apps/admin/pages/merchants/create.vue?macro=true";
import { default as indexKvKHtgwECbMeta } from "/app/apps/admin/pages/merchants/index.vue?macro=true";
import { default as _91id_93bRNHUGgA7rMeta } from "/app/apps/admin/pages/providers/[id].vue?macro=true";
import { default as createvLXDhvsderMeta } from "/app/apps/admin/pages/providers/create.vue?macro=true";
import { default as indexPUaD5RluVQMeta } from "/app/apps/admin/pages/providers/index.vue?macro=true";
import { default as indexLSaiCBNZedMeta } from "/app/apps/admin/pages/refs/index.vue?macro=true";
import { default as routingp6SDuVcNzxMeta } from "/app/apps/admin/pages/routing.vue?macro=true";
import { default as indexmTxcUyPu0eMeta } from "/app/apps/admin/pages/tariffs/index.vue?macro=true";
import { default as indexGb8nJ9Auq2Meta } from "/app/apps/admin/pages/transactions/index.vue?macro=true";
import { default as _91id_93zmyL9SuQbzMeta } from "/app/apps/admin/pages/users/[id].vue?macro=true";
import { default as createrzDewVk25SMeta } from "/app/apps/admin/pages/users/create.vue?macro=true";
import { default as indeximPu7gfKFmMeta } from "/app/apps/admin/pages/users/index.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginmNRU5AbfbnMeta || {},
    component: () => import("/app/apps/admin/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "finance",
    path: "/finance",
    meta: index1sKXKiOnAvMeta || {},
    component: () => import("/app/apps/admin/pages/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexRhbCo9xKafMeta || {},
    component: () => import("/app/apps/admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "merchants-id",
    path: "/merchants/:id()",
    meta: _91id_938P31KSEikOMeta || {},
    component: () => import("/app/apps/admin/pages/merchants/[id].vue").then(m => m.default || m)
  },
  {
    name: "merchants-create",
    path: "/merchants/create",
    meta: createEoHtO5AzvfMeta || {},
    component: () => import("/app/apps/admin/pages/merchants/create.vue").then(m => m.default || m)
  },
  {
    name: "merchants",
    path: "/merchants",
    meta: indexKvKHtgwECbMeta || {},
    component: () => import("/app/apps/admin/pages/merchants/index.vue").then(m => m.default || m)
  },
  {
    name: "providers-id",
    path: "/providers/:id()",
    meta: _91id_93bRNHUGgA7rMeta || {},
    component: () => import("/app/apps/admin/pages/providers/[id].vue").then(m => m.default || m)
  },
  {
    name: "providers-create",
    path: "/providers/create",
    meta: createvLXDhvsderMeta || {},
    component: () => import("/app/apps/admin/pages/providers/create.vue").then(m => m.default || m)
  },
  {
    name: "providers",
    path: "/providers",
    meta: indexPUaD5RluVQMeta || {},
    component: () => import("/app/apps/admin/pages/providers/index.vue").then(m => m.default || m)
  },
  {
    name: "refs",
    path: "/refs",
    meta: indexLSaiCBNZedMeta || {},
    component: () => import("/app/apps/admin/pages/refs/index.vue").then(m => m.default || m)
  },
  {
    name: "routing",
    path: "/routing",
    meta: routingp6SDuVcNzxMeta || {},
    component: () => import("/app/apps/admin/pages/routing.vue").then(m => m.default || m)
  },
  {
    name: "tariffs",
    path: "/tariffs",
    meta: indexmTxcUyPu0eMeta || {},
    component: () => import("/app/apps/admin/pages/tariffs/index.vue").then(m => m.default || m)
  },
  {
    name: "transactions",
    path: "/transactions",
    meta: indexGb8nJ9Auq2Meta || {},
    component: () => import("/app/apps/admin/pages/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: _91id_93zmyL9SuQbzMeta || {},
    component: () => import("/app/apps/admin/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: createrzDewVk25SMeta || {},
    component: () => import("/app/apps/admin/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indeximPu7gfKFmMeta || {},
    component: () => import("/app/apps/admin/pages/users/index.vue").then(m => m.default || m)
  }
]