export function useApiUrl() {
    let apiUrl = useRuntimeConfig().public.API_URL;

    if (apiUrl === undefined) {
        const hostname = location.hostname.split('.');

        hostname[0] = 'apia';
        apiUrl = hostname.join('.');
    }

    return apiUrl;
}