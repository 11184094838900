export default defineNuxtPlugin((nuxtApp) => {
    const { token } = useAuth();

    const apiUrl = useApiUrl();

    const $api = $fetch.create({
        baseURL: apiUrl,
        onRequest({ request, options, error }) {
            if (token) {
                // Add Authorization header
                options.headers = options.headers || {}
                options.headers.Authorization = `${token.value}`
            }
        },
        async onResponseError({ response }) {
            if (response.status === 401) {
                await navigateTo('/auth/login')
            }
        }
    })
    return {
        provide: {
            platformaticApiUrl: apiUrl,
            platformaticApi: $api
        }
    }
})
