export default defineNuxtPlugin(() => {
    const { token } = useAuth();
    const paymentApiUrl = usePaymentApiUrl();

    const $api = $fetch.create({
        baseURL: paymentApiUrl,
        onRequest({ request, options, error }) {
            if (token) {
                // Add Authorization header
                options.headers = options.headers || {}
                options.headers.Authorization = `${token.value}`
            }
        },
        async onResponseError({ response }) {
            if (response.status === 401) {
                await navigateTo('/auth/login')
            }
        }
    });
    return {
        provide: {
            paymentApiV1Url: paymentApiUrl,
            paymentApiV1: $api
        }
    }
})
