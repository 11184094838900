const ADMIN_LINKS = [
    {
        name: 'Merchants',
        to: '/merchants'
    },
    {
        name: 'Providers',
        to: '/providers'
    },
    {
        name: 'Tariffs',
        to: '/tariffs'
    },
    {
        name: "Transactions",
        to: '/transactions'
    },
    {
        name: "Users",
        to: '/users'
    },
    {
        name: "Refs",
        to: '/refs'
    },
    {
        name: "Fin",
        to: '/finance'
    }
];

export function getLayoutLinks(userRole: 'admin' | 'support') {
    const appConfig = useAppConfig();

    switch (userRole) {
        case 'admin': {
            return ADMIN_LINKS;
        }
        case 'support': {
            return appConfig.layout.navigation.items.filter(item => item.to === '/transactions');
        }
        default: {
            return appConfig.layout.navigation.items;
        }
    }
}