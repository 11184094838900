import revive_payload_client_veQK773nwc from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EzHSwROBNe from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9aqj1gJ8ZL from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZddoxbrWqk from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vzBwSBPVYw from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_6xzU7OArq1 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_A2nArG9lwA from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_lightningcss@_66cyvdezjd624a5acxox64npp4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_D4HFZpYKjI from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.2_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+core@7.25.2_@ope_6lhe2ctturjmrd5siy5qxs7rte/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import paymentApiV1_fc0kMFsFCb from "/app/packages/authLayer/plugins/paymentApiV1.ts";
import platformaticApi_WLaoWQiCGl from "/app/packages/authLayer/plugins/platformaticApi.ts";
export default [
  revive_payload_client_veQK773nwc,
  unhead_EzHSwROBNe,
  router_9aqj1gJ8ZL,
  payload_client_ZddoxbrWqk,
  navigation_repaint_client_vzBwSBPVYw,
  chunk_reload_client_6xzU7OArq1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_A2nArG9lwA,
  plugin_D4HFZpYKjI,
  paymentApiV1_fc0kMFsFCb,
  platformaticApi_WLaoWQiCGl
]