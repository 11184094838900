export function usePaymentApiUrl() {
    let paymentApiUrl = useRuntimeConfig().public.PAYMENT_API_URL;

    if (paymentApiUrl === undefined) {
        const hostname = location.hostname.split('.');

        hostname[0] = 'api';
        paymentApiUrl = hostname.join('.');
    }

    return paymentApiUrl;
}