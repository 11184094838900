export default defineAppConfig({
    layout: {
        showTitle: true,
        navigation: {
            enabled: true,
            logo: {
                component: 'div'
            },
            header: {
                /*component: 'WorkspaceDropdown'*/
            },
            items: [
                {
                    name: "Transactions",
                    to: '/transactions'
                }
            ]
        },
        toolbar: {
            enabled: true,
            component: 'AdminToolbar',
        },
    }
});
