export default defineNuxtRouteMiddleware(async (to) => {
    const {status} = useAuth();
    const appConfig = useAppConfig();
    const isAdmin = useIsAdmin();

    if (status.value === 'unauthenticated') {
        return;
    }

    if (isAdmin.value) {
        appConfig.layout.navigation.items = getLayoutLinks('admin');

        return;
    }

    appConfig.layout.navigation.items = getLayoutLinks('support');

    return to.path === '/transactions' ? undefined : navigateTo('/transactions');
});